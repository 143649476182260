<template>
  <div class="mx-auto" style="max-width: 1200px;">
    <slot />
  </div>
</template>

<script setup lang='ts'>

</script>

<style>

</style>
